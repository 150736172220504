<template>
    <div id="BindApp" style="height: 100%">
        <a-layout style="height: 100%">
            <a-layout-content style="height: 100%">
                <div class="yj-conten"
                     style="
            min-height: calc(100% - 48px);
            background-color: #fff;
            width: calc(100% - 32px);
          ">
                    <a-card title="物料素材绑定"
                            style="width: 100%; min-height: 100%"
                            :bordered="false">
                        <a-form-model layout="horizontal"
                                      style="width: 100%"
                                      ref="ruleForm"
                                      :label-col="formItemLayout.labelCol"
                                      :wrapper-col="formItemLayout.wrapperCol">
                            <a-row style="margin: 20px 0 0 0">
                                <a-col :span="8">
                                    <label>绑定类型：</label>
                                    <br />
                                    <a-select v-model="BindType"
                                              @change="BindTypechange"
                                              style="width: 150px">
                                        <a-select-option :value="0" selected="selected">摇一摇活动</a-select-option>
                                        <a-select-option :value="1">素材</a-select-option>
                                        <a-select-option :value="2">小店</a-select-option>
                                    </a-select>
                                </a-col>
                                <a-col v-show="show" :span="8">
                                    <label>素材类型：</label>
                                    <br />
                                    <a-select v-model="MaterialType"
                                              @change="MaterialTypechange"
                                              style="width: 150px">
                                        <a-select-option :value="0">文章</a-select-option>
                                        <a-select-option :value="1">H5</a-select-option>
                                        <a-select-option :value="2">海报</a-select-option>
                                    </a-select>
                                </a-col>
                                <!--                <a-col v-show="ImgShow" :span="8">-->
                                <!--                  <label>素材截图：</label>-->
                                <!--                  <a-card style="width: 300px">-->
                                <!--                    <img-->
                                <!--                      :src="WebImg"-->
                                <!--                      slot="cover"-->
                                <!--                      id="shareqr"-->
                                <!--                      class="thumb-img"-->
                                <!--                      style="width: 300px"-->
                                <!--                    />-->
                                <!--                  </a-card>-->
                                <!--                </a-col>-->
                            </a-row>
                            <a-row v-show="Listshow"
                                   :gutter="[40, 45]"
                                   style="margin: 15px 0">
                                <a-col :span="4" v-for="info in tableData" :key="info.ID">
                                    <a-card hoverable
                                            style="overflow: hidden; position: relative"
                                            @click="NewShowDetail(info)">
                                        <img slot="cover"
                                             class="thumb-img"
                                             :src="Getsrc(info)"
                                             @click="NewShowDetail(info)" />
                                        <div class v-if="info.AwardPoolID != null">
                                            <span v-if="info.AwardPoolStatus == 1"
                                                  class="yh-card bhyhq">优惠卷</span>
                                            <span v-if="info.AwardPoolStatus == 2"
                                                  class="yh-card noyhq">优惠卷</span>
                                        </div>

                                        <div class v-if="info.MaterialType == 1">
                                            <span v-if="info.CreateTypeStatus == 0"
                                                  class="yh-articlecard articlebhyhq">自创</span>
                                            <span v-if="info.CreateTypeStatus == 1"
                                                  class="yh-articlecard articlebhyhq">订阅</span>
                                        </div>
                                        <a-card-meta :title="info.Name.length > 0 ? info.Name : info.Author">
                                            <template slot="description">
                                                <a-row type="flex" justify="space-around">
                                                    <a-col :span="14">
                                                        <a-radio-group name="radioGroup" :default-value="1">
                                                            <a-button type
                                                                      size="small"
                                                                      @click.stop="BindMaterial(info.ID)"
                                                                      v-show="Imgshow0">
                                                                <!--                              文章-->
                                                                <a-radio><a-icon type="link"></a-icon>绑定</a-radio>
                                                            </a-button>
                                                            <a-button @click.stop="MaterialDetail(info.ID,info.Logo)"
                                                                      type
                                                                      size="small"
                                                                      v-show="ImgShow">
                                                                <!--                               H5/海报-->
                                                                <a-icon type="link"></a-icon>绑定
                                                            </a-button>
                                                        </a-radio-group>
                                                    </a-col>
                                                </a-row>
                                            </template>
                                        </a-card-meta>
                                    </a-card>
                                </a-col>
                            </a-row>
                            <a-row v-show="Listshow" :gutter="[20, 20]">
                                <a-col :span="24" align="center">
                                    <a-pagination v-model="current"
                                                  :total="total"
                                                  :defaultPageSize="defaultPageSize"
                                                  :showTotal="showTotal"
                                                  @change="pagerChange" />
                                </a-col>
                            </a-row>
                        </a-form-model>
                    </a-card>
                    <a-card :bordered="false" v-show="disable">
                        <a-button type="primary" @click="SubmitModel"> 保存 </a-button>
                    </a-card>
                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script src="https://api.map.baidu.com/api?v=2.0&ak=E8f3c743837925ce120eb93417f478db"></script>
<script src="../js/html2canvas.min.js"></script>
<script>
    import http from "@/Plugin/Http.js";
    import local from "@/Plugin/local.js";
    // import imgapi from "@/Plugin/img.js";
    // import html2canvas from "html2canvas";
    // import materialDetail from "../PromoteMaterial/MaterialDetail.vue"
    export default {
        name: "",
        data() {
            return {
                disable: true,
                Imgshow0: true,
                checked: false,
                visible: false,
                show: false,
                Listshow: false,
                ImgShow: false,
                Display: false,
                Detailshow: false,
                WebImg: "",
                QRMaterialID: "",
                MaterialID: "",
                StartNum: "",
                EndNum: "",
                BindType: 0,
                MaterialType: 0,
                total: 0,
                defaultPageSize: 12,
                current: 1,
                thispage: 0,
                showTotal: (total) => `共 ${total} 条数据`,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 5 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 19 },
                    },
                },
                SelectType: "radio",
                tableData: [],
                pagination: {
                    total: 0,
                    defaultPageSize: 0,
                    current: 1,
                    showTotal: (total) => `共 ${total} 条数据`,
                },
                columns: [
                    {
                        title: "素材名称",
                        width: 150,
                        dataIndex: "Name",
                    },
                    {
                        title: "创建时间",
                        width: 200,
                        dataIndex: "InputTime",
                    },
                    {
                        title: "素材类型",
                        width: 200,
                        customRender: (text, item) => {
                            var rt;
                            switch (item.MaterialType) {
                                case 1:
                                    rt = "文章";
                                    break;
                                case 3:
                                    rt = "H5";
                                    break;
                                case 5:
                                    rt = "海报";
                                    break;
                            }
                            return rt;
                        },
                    },
                ],
            };
        },
        props: {
            //组件属性
        },
        methods: {
            SubmitModel() {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/UpdateQRMatieralBind",
                    data: {
                        QRMatieralID: self.QRMaterialID,
                        BindType: self.BindType,
                        MaterialID: self.MaterialID,
                        WebImg: self.WebImg,
                        StartNum: self.StartNum,
                        EndNum: self.EndNum
                    },
                    OnSuccess: function (response) {
                        self.$message.success("保存成功");
                        local.clearlocal();
                        self.$router.push({
                            name: "PromoteMaterial_List",
                        });
                    },
                };
                http.Post(op);
            },
            MaterialtableData: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Promote/GetMaterialList",
                    data: {
                        MaterialType: self.MaterialType,
                        pageIndex: self.current,
                    },
                    OnSuccess: function (data) {
                        self.tableData = data.data.Data;
                        self.total = data.data.Total;
                        self.defaultPageSize = data.data.RowCount;
                        self.current = data.data.PageIndex;
                    },
                };
                http.Post(op);
            },
            BindMaterial: function (item) {
                let self = this;
                self.disable = false;
                self.MaterialID = item;
                localStorage.ID = item;
                self.QRMatieralID = localStorage.QRMaterialID;
                self.SubmitModel();
            },
            Getsrc: function (info) {
                var rt = info.Logo;
                if (rt == null) {
                    rt = "#";
                } else {
                    if (rt.indexOf("upload2") < 0) {
                        rt = rt.replace(/upload\//g, "upload2/");
                    }
                }
                return rt;
            },
            pagerChange: function (item) {
                var self = this;
                self.pagination.current = item;
                self.current = item;
                self.MaterialtableData();
            },
            //绑定类型下拉框修改
            BindTypechange: function () {
                var self = this;
                console.log("self.BindType", self.BindType);
                if (self.BindType == 0) {
                    self.disable = true;
                    self.show = self.Listshow = self.ImgShow = false;
                    self.MaterialType = 0;
                    self.MaterialID = "";
                    //localStorage.clearlocal();
                    //local.clearlocal();
                } else if (self.BindType == 1) {
                    if (self.MaterialType == 0) {
                        self.Imgshow = false;
                        self.disable = false;
                    } else {
                        self.Imgshow = true;
                    }
                    self.show = self.Listshow = true;
                    self.pagination.current = self.current = 1;
                    self.MaterialtableData();
                }
                else if (self.BindType == 2) {
                    self.disable = true;
                    self.show = self.Listshow = self.ImgShow = false;
                    self.MaterialType = 0;
                    self.MaterialID = "";
                    //local.clearlocal();
                    //localStorage.clearlocal();
                }


            },

            //素材类型下拉选择
            MaterialTypechange: function () {
                var self = this;
                self.Listshow = true;
                if (self.MaterialType == 0) {
                    self.ImgShow = false;
                    self.Imgshow0 = true;
                } else {
                    self.ImgShow = true;
                    self.Imgshow0 = false;
                }
                self.pagination.current = self.current = 1;
                self.MaterialtableData();
            },
            MaterialDetail: function (ID, img) {
                let self = this;
                self.MaterialID = ID;
                console.log("素材ID：", ID)
                console.log("素材图片：", img)
                self.BindType = 1;//素材
                self.WebImg = img;
                self.disable = false;
                localStorage.ID = ID;
                self.QRMatieralID = localStorage.QRMaterialID;
                self.SubmitModel();
                // local.setlocal("page", self.current);
                // local.setlocal("BindType", self.BindType);
                // local.setlocal("MaterialType", self.MaterialType);
                // local.setlocal("StartNum", self.StartNum);
                // local.setlocal("EndNum", self.EndNum);
                // local.setlocal("type", "detail");
                // local.setlocal("ID", ID);
                // local.setlocal("Action","截图");
                //     self.$router.push({
                //         name: "PromoteMaterial_MaterialDetail"})
                //
                //   //self.visible=true;
                // },

            },
        },
        computed: {
            //计算属性
        },

        components: {
            //组件
            // materialDetail,
            //TurnTableConfig,
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成s
            var self = this;
            // self.QRMaterialID = self.$route.query.QRMaterialID;
            // self.StartNum = self.$route.query.StartNum;
            // self.EndNum = self.$route.query.EndNum;
            if (localStorage.type == "return") {
                self.pagination.current = self.current = parseInt(localStorage.page);
                self.BindType = parseInt(local.getlocal("BindType"));
                self.MaterialType = parseInt(local.getlocal("MaterialType"));
                self.StartNum = local.getlocal("StartNum");
                self.EndNum = local.getlocal("EndNum");
                self.value = local.getlocal("value");
                self.QRMaterialID = localStorage.QRMaterialID;
                self.MaterialID = localStorage.ID;
                self.WebImg = localStorage.img;
                self.BindTypechange();
                self.MaterialTypechange();
                self.MaterialtableData();
            } else {
                localStorage.QRMaterialID = self.QRMaterialID = self.$route.query.ID;
                self.StartNum = self.$route.query.StartNum;
                self.EndNum = self.$route.query.EndNum;
            }
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
            if (localStorage.type != "detail") {
                local.clearlocal();
                self.show = false;
                self.Listshow = false;
                self.ImgShow = false;
                self.ImgShow0 = false;
                self.MaterialType = 0;
            }
            //local.clearlocal();
        },
        destroyed: function () {
            //生命周期，销毁完成
        },

    }
</script>
<style type="text/css">
    .bm-view {
        width: 100%;
        height: 600px;
    }

    #suggestId {
        width: 500px;
        top: -68px;
        margin-left: 100px;
    }

    #map_title {
        position: absolute;
        z-index: 9999;
        left: 23px;
        right: 23px;
        height: 60px;
        background-color: #09f;
        padding-top: 10px;
        padding-left: 20px;
        color: white;
    }

    .tangram-suggestion {
        z-index: 199999;
    }

    .thumb-img {
        height: 150px;
        object-fit: cover;
        outline: none;
        background: url("../../../../src/assets/nourl.png");
        background-size: cover;
    }

    #map_sel {
        position: absolute;
        z-index: 9999;
        right: 106px;
        top: 92px;
    }

    #map_btns {
        position: absolute;
        z-index: 9999;
        bottom: 30px;
        width: 100%;
    }

        #map_btns input[type="button"] {
            width: 120px;
            height: 45px;
            margin-top: 20px;
            color: #fff;
            border: none;
            border-radius: 30px;
            opacity: 0.8;
        }

    .map_save {
        background-color: green;
        margin-right: 40px;
    }

    .map_cancel {
        background-color: #f15b5b;
    }

    .solid-btn {
        background: #32a9ff;
        color: #fff !important;
        padding: 4px 18px;
        border: none;
        border-radius: 2px;
        transition: all 0.15s ease-in-out;
        margin-top: -3px;
    }

    .yj-storecon-template > div {
        height: 100px;
        width: 100px;
        overflow: hidden;
        text-align: center;
        flex-shrink: 0;
    }

    .yh-articlecard {
        position: absolute;
        right: -45px;
        top: 5px;
        display: inline-block;
        padding: 2px 45px;
        letter-spacing: 2px;
        font-size: 8px;
        transform: rotateZ(45deg);
        border-radius: 5px 0 0 5px;
    }

    .articlebhyhq {
        background: #f20;
        color: #fff;
    }
</style>
